import { Box, BoxProps } from "components/ui/box";
import React from "react";

import Image from "next/image";
import {
  Container,
  ResponsiveText,
  ResponsiveTextProps,
} from "../marketing-pages/front-page/shared";
import { MainBackgroundWrapper } from "modules/non-protected-page/shared";

import CheckIcon from "@mui/icons-material/Check";
import { AutoPlayVideo } from "components/video/auto-play-video";
import { Typography } from "components/ui/Typography";

interface FeatureItem {
  type: "image" | "video";
  headline: string;
  content: string[];
  imageUrl: string;
  videoUrl?: string;
  imageAlt?: string;
}

const FullWidthVideo = ({ imageUrl, videoUrl, headline, content }) => {
  return (
    <Box
      component="article"
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "24px 0",
      }}
    >
      <Box
        sx={{
          minWidth: {
            xs: "initial",
            sm: "initial",
            md: "initial",
            lg: "initial",
            xl: "500px",
          },
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          position: "relative",
          marginBottom: "24px",
        }}
      >
        <ResponsiveText
          variant="h4"
          fontSize={30}
          sx={{
            marginBottom: "24px",
            color: "text.white",
            fontWeight: 600,
          }}
        >
          {headline}
        </ResponsiveText>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
          }}
        >
          {content.map((text, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "24px",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "flex-start",
                    columnGap: "6px",
                  }}
                >
                  <CheckIcon
                    sx={{
                      fill: "#B354EA",
                    }}
                  />
                  <ResponsiveText
                    variant="body1"
                    fontSize={16}
                    fontWeight={600}
                    sx={{
                      color: "#D4DCE5",
                    }}
                  >
                    {text}
                  </ResponsiveText>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          padding: {
            xs: "15px",
            sm: "15px",
            md: "30px",
            lg: "30px",
            xl: "30px",
          },
          borderRadius: "22px",
        }}
      >
        <AutoPlayVideo
          imageUrl={imageUrl}
          videoUrl={videoUrl}
          style={{
            width: "100%",
            borderRadius: "22px",
          }}
          muted
        />
      </Box>
    </Box>
  );
};

export const MainFeatureItem = ({
  featureItem,
}: {
  featureItem: FeatureItem;
}) => {
  return (
    <Box
      component="article"
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "124px",
      }}
    >
      <Box
        sx={{
          maxWidth: "600px",
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          textAlign: "center",
          position: "relative",
          margin: "48px 0",
        }}
      >
        <ResponsiveText
          variant="h2"
          fontSize={44}
          sx={{
            marginBottom: "24px",
            color: "text.white",
            fontWeight: 600,
          }}
        >
          {featureItem.headline}
        </ResponsiveText>
        {featureItem.content.map((text, index) => {
          return (
            <ResponsiveText
              variant="body1"
              key={index}
              fontSize={20}
              className="with-html-black-bg"
              fontWeight={600}
              dangerouslySetInnerHTML={{ __html: text }}
              sx={{
                color: "#D4DCE5",
              }}
            ></ResponsiveText>
          );
        })}
      </Box>

      {featureItem.videoUrl ? (
        <Box
          sx={{
            background:
              "linear-gradient(#B354EA 0%, #590D85 48.21%, #240337 88.39%, rgba(179, 84, 234, 1) 96.39%)",
            padding: {
              xs: "15px",
              sm: "15px",
              md: "30px",

              lg: "30px",
              xl: "30px",
            },
            borderRadius: "22px",
          }}
        >
          <AutoPlayVideo
            imageUrl={featureItem.imageUrl}
            videoUrl={featureItem.videoUrl}
            style={{
              width: "100%",
              borderRadius: "22px",
            }}
            muted
          />
        </Box>
      ) : (
        <Image
          src={featureItem.imageUrl}
          alt={featureItem.imageAlt}
          height={500}
          width={1000}
          placeholder="blur"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
          }}
        />
      )}
    </Box>
  );
};

export const FeatureItem = ({
  headline,
  content,
  imageAlt,
  imageUrl,
  direction,
  type,
  videoUrl,
  useCheckMark = true,
  headlineProps = {},
  contentProps = {},
  contentRootProps = {},
  columnReverseOnMobile,
  slot,
  imageBeforeTitleOnMobile,
  asItem,
}: typeof featureData[number] & {
  direction: "left" | "right";
  contentProps?: ResponsiveTextProps;
  headlineProps?: ResponsiveTextProps;
  useCheckMark?: boolean;
  columnReverseOnMobile?: boolean;
  slot?: {
    end: React.ReactNode;
  };
  imageBeforeTitleOnMobile?: boolean;
}) => {
  const rowDirection = direction === "left" ? "row" : "row-reverse";

  if (type === "video" && !asItem) {
    return (
      <FullWidthVideo
        headline={headline}
        videoUrl={videoUrl}
        imageUrl={imageUrl}
        content={content}
      />
    );
  }
  return (
    <Box
      component="article"
      sx={{
        padding: "0 24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          alignItems: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "flex-start",
            xl: "flex-start",
          },
          columnGap: "48px",
          rowGap: "48px",
          width: "100%",
          flexDirection: {
            xs: columnReverseOnMobile ? "column-reverse" : "column",
            sm: columnReverseOnMobile ? "column-reverse" : "column",
            md: columnReverseOnMobile ? "column-reverse" : "column",
            lg: rowDirection,
            xl: rowDirection,
          },
        }}
      >
        <Box
          sx={{
            maxWidth: "500px",
            minWidth: {
              xs: "initial",
              sm: "initial",
              md: "initial",
              lg: "initial",
              xl: "500px",
            },
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            position: "relative",
          }}
        >
          <ResponsiveText
            variant="h4"
            decrement="medium"
            fontSize={30}
            {...headlineProps}
            sx={{
              marginBottom: "24px",
              color: "text.white",
              fontWeight: 600,
              "& span": {
                fontSize: "inherit",
              },
              ...(headlineProps.sx || {}),
            }}
          >
            {headline}
          </ResponsiveText>
          {imageBeforeTitleOnMobile ? (
            <Box
              sx={{
                position: "relative",
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "none",
                  xl: "none",
                },
                flexBasis: "60%",
                height: {
                  sm: "initial",
                  xs: "initial",
                  md: "initial",
                  lg: "100%",
                  xl: "100%",
                },
                flexShrink: 0,
                "& img": {
                  maxWidth: {
                    xs: "initial",
                    sm: "initial",
                    md: "initial",
                    lg: "500px",
                    xl: "500px",
                  },
                  height: {
                    sm: "100%",
                    xs: "100%",
                    md: "initial",
                    lg: "initial",
                    xl: "initial",
                  },
                },
              }}
            >
              {type === "image" ? (
                <Image
                  src={imageUrl}
                  height={500}
                  placeholder="blur"
                  alt={imageAlt}
                  style={{
                    borderRadius: "22px",
                    height: "100%",
                    objectFit: "contain",
                    width: "100%",
                  }}
                  width={600}
                />
              ) : null}
              {type === "video" ? (
                <AutoPlayVideo
                  imageUrl={imageUrl}
                  videoUrl={videoUrl}
                  width={400}
                />
              ) : null}
            </Box>
          ) : null}
          <Box
            component={typeof content === "string" ? "div" : "ul"}
            sx={{
              listStyle: "none",
              padding: 0,
              display: "flex",
              flexDirection: "column",
              rowGap: "24px",
              ...(contentRootProps.sx || {}),
            }}
          >
            {typeof content === "string" ? (
              <ResponsiveText
                component={typeof content === "string" ? "p" : "li"}
                variant="body1"
                fontSize={16}
                fontWeight={600}
                {...contentProps}
                sx={{
                  color: "#D4DCE5",
                  ...(contentProps.sx || {}),
                }}
              >
                {content}
              </ResponsiveText>
            ) : (
              content.map((text, index) => {
                return (
                  <Box
                    key={index}
                    component="li"
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      columnGap: "24px",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "flex-start",
                        columnGap: "6px",
                      }}
                    >
                      {useCheckMark && (
                        <CheckIcon
                          sx={{
                            fill: "#B354EA",
                          }}
                        />
                      )}
                      <ResponsiveText
                        variant="body1"
                        fontSize={16}
                        fontWeight={600}
                        {...contentProps}
                        sx={{
                          color: "#D4DCE5",
                          ...(contentProps.sx || {}),
                        }}
                        dangerouslySetInnerHTML={{ __html: text }}
                      ></ResponsiveText>
                    </Box>
                  </Box>
                );
              })
            )}
          </Box>
          {slot?.end ? slot.end : null}
        </Box>
        <Box
          sx={{
            position: "relative",
            display: {
              xs: imageBeforeTitleOnMobile ? "none" : "flex",
              sm: imageBeforeTitleOnMobile ? "none" : "flex",
              md: imageBeforeTitleOnMobile ? "none" : "flex",
              lg: "flex",
              xl: "flex",
            },
            flexBasis: type === "video" ? "initial" : "60%",
            height: {
              sm: "initial",
              xs: "initial",
              md: "initial",
              lg: "100%",
              xl: "100%",
            },
            flexShrink: 0,
            "& img": {
              maxWidth: {
                xs: "initial",
                sm: "initial",
                md: "initial",
                lg: "500px",
                xl: "500px",
              },
              height: {
                sm: "100%",
                xs: "100%",
                md: "initial",
                lg: "initial",
                xl: "initial",
              },
            },
            "& video": {
              maxWidth: {
                xs: "initial",
                sm: "initial",
                md: "initial",
                lg: "600px",
                xl: "600px",
              },
              height: {
                sm: "100%",
                xs: "100%",
                md: "initial",
                lg: "initial",
                xl: "initial",
              },
            },
          }}
        >
          {type === "image" ? (
            <Image
              src={imageUrl}
              height={500}
              placeholder="blur"
              alt={imageAlt}
              style={{
                borderRadius: "22px",
                height: "100%",
                objectFit: "contain",
                width: "100%",
              }}
              width={600}
            />
          ) : null}
          {type === "video" ? (
            <AutoPlayVideo
              imageUrl={imageUrl}
              videoUrl={videoUrl}
              style={{
                width: "100%",
                borderRadius: "22px",
              }}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const FeatureList = ({
  mainItem,
  featureData,
  headline,
  sx = {},
}: {
  mainItem?: FeatureItem;
  featureData: FeatureItem[];
  headline: string;
  sx: BoxProps["sx"];
}) => {
  return (
    <MainBackgroundWrapper
      component="section"
      sx={{
        padding: "70px 0 120px 0",
        ...sx,
      }}
    >
      <Container>
        {mainItem && <MainFeatureItem featureItem={mainItem} />}
        <ResponsiveText
          variant="h3"
          fontSize={44}
          color="text.white"
          fontWeight={500}
          marginBottom={"48px"}
          textAlign={"center"}
        >
          {headline}
        </ResponsiveText>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            columnGap: "48px",
            rowGap: "48px",
            margin: "0 12px",
          }}
        >
          {featureData.map((item, index) => {
            return (
              <FeatureItem
                {...item}
                key={index}
                direction={index % 2 === 0 ? "right" : "left"}
              />
            );
          })}
        </Box>
      </Container>
    </MainBackgroundWrapper>
  );
};

export default FeatureList;
