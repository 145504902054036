import { serverRoutes } from "client-server-shared/config/routes";
import { useUser } from "hooks/use-user";
import { NextLink } from "components/next-link";
import { Button, IconButton, IconButtonProps } from "components/ui/button";
import Link from "next/link";
import { InlineSpan } from "components/as-inline-span";
import { Avatar } from "components/ui/avatar";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

export type PrimaryCallToActionProps = Exclude<
  CallToActionProps,
  "asBlank" | "variant"
>;

export const HighlightedCallToAction = ({
  children,
  rootProps = {},
  route,
}: {
  route?: string;
  children: React.ReactNode;
  rootProps?: PrimaryCallToActionProps;
}) => {
  return (
    <PrimaryCallToAction
      to={serverRoutes.register}
      route={route}
      {...rootProps}
      sx={{
        maxWidth: "initial",
        padding: "6px 6px 6px 12px",
        minWidth: "initial",
        fontSize: "16px",
        boxShadow: "rgba(100, 100, 111, 0.5) 0px 7px 29px 0px",
        background: "rgba(145, 60, 211, 0.69)",
        border: 0,
        columnGap: "12px",
        fontWeight: 500,
        "&:hover": {
          background: "rgba(145, 60, 211, 0.69)",
        },
        ...(rootProps.sx || {}),
      }}
    >
      <InlineSpan component="strong" inherit>
        {children}
      </InlineSpan>
      <Avatar
        sx={{
          height: "36px",
          width: "36px",
        }}
      >
        <KeyboardDoubleArrowRightOutlinedIcon
          sx={{
            fill: "black",
          }}
        />
      </Avatar>
    </PrimaryCallToAction>
  );
};

export const PrimaryCallToAction = ({
  children,
  to,
  route,
  sx = {},
  ...rest
}: Exclude<CallToActionProps, "asBlank" | "variant">) => {
  const user = useUser();
  return (
    <CallToAction
      to={route ? route : user ? to : serverRoutes.register}
      sx={sx}
      {...rest}
    >
      {children}
    </CallToAction>
  );
};

export const SolidCallToAction = ({
  children,
  to,
  sx = {},
  onClick = () => {},
}: Exclude<CallToActionProps, "asBlank" | "variant">) => {
  return (
    <Button
      variant="outlined"
      component={Link}
      href={to}
      onClick={onClick}
      capitalize
      sx={{
        color: "text.white",
        background: "#5B2D75",
        padding: "12px 24px",
        borderRadius: "22px",
        whiteSpace: "nowrap",
        border: 0,
        "&:hover": {
          background: "#5B2D75",
          border: 0,
          opacity: 0.8,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export type CallToActionProps = {
  children: React.ReactNode;
  variant: "primary" | "secondary";
  to: string;
  asBlank?: boolean;
  onClick?: () => void;
} & IconButtonProps;

export const CallToAction = ({
  children,
  variant = "primary",
  to,
  sx = {},
  asBlank = false,
  onClick = () => {},
  rel,
  ...rest
}: CallToActionProps) => {
  const props = {};
  if (asBlank) {
    props["target"] = "__blank";
  }
  return (
    <IconButton
      component={NextLink}
      href={to}
      rel={rel}
      sx={{
        display: "flex",
        color: "text.white",
        margin: "48px auto 124px auto",
        borderRadius: "50px",
        maxWidth: "300px",
        border: "3px solid #B354EA",
        width: "100%",
        fontSize: {
          xs: "1.2rem",
          sm: `1.2rem`,
          md: `1.5rem`,
          xl: `1.5rem`,
        },
        background:
          variant === "primary"
            ? "linear-gradient(258.52deg, #1C002C 33.72%, rgba(56, 13, 81, 0.407395) 47.95%, rgba(102, 137, 207, 0.526426) 101.13%, rgba(121, 171, 248, 0) 150.29%)"
            : "linear-gradient(258.52deg, #1C002C 100%, rgba(56, 13, 81, 0.407395) 47.95%, rgba(102, 137, 207, 0.526426) 101.13%, rgba(121, 171, 248, 0) 0.29%)",
        ...sx,
      }}
      {...props}
      {...rest}
    >
      {children}
    </IconButton>
  );
};
