import React from "react";
import { useInView } from "react-intersection-observer";

export const Video = ({
  videoUrl,
  imageUrl,
  ...rest
}: {
  videoUrl: string;
  imageUrl: string;
}) => {
  return (
    <video
      preload="none"
      poster={imageUrl}
      controls
      src={videoUrl}
      muted
      {...rest}
    />
  );
};

export const AutoPlayVideo = ({
  videoUrl,
  imageUrl,
  delay = 0,
  disabledAutoPlay,
  ...rest
}: {
  videoUrl: string;
  imageUrl: string;
}) => {
  const ref = React.useRef(null);
  const autoplay = React.useRef(false);
  const [inViewRef, inView] = useInView({
    initialInView: false,
    fallbackInView: true,
    triggerOnce: true,
    threshold: 0.7,
  });
  const setRefs = React.useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );
  React.useEffect(() => {
    if (autoplay.current) {
      return;
    }
    if (rest.autoPlay) {
      return;
    }
    if (disabledAutoPlay) {
      return;
    }

    if (inView && ref && ref.current) {
      setTimeout(() => {
        ref.current.play();
        ref.current.autoPlay = true;
      }, delay);
      autoplay.current = true;
    }
  }, [ref, inView, rest.autoPlay]);
  return (
    <video
      ref={setRefs}
      playsInline
      loop
      preload="metadata"
      poster={imageUrl}
      controls
      src={videoUrl}
      muted
      {...rest}
    />
  );
};
